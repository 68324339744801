import React, { useEffect, useRef } from "react";
import Header from "../organisms/Header/Header";
import Footer from "../organisms/Footer/Footer";
import MobileMenu from "../organisms/MobileMenu/MobileMenu";
import MobileFooter from "../organisms/MobileFooter/MobileFooter";
import apiClient from "../../apiClient";

function Layout({ children }) {
  // Ref to keep track of whether scripts have been injected
  const snippetsInjected = useRef(false);

  // Inject analytics snippets into the document head
  const injectSnippets = async () => {
    try {
      const response = await apiClient.get("/analytics-snippets");
      const activeSnippets = response.data;

      if (Array.isArray(activeSnippets)) {
        activeSnippets.forEach((snippetObj) => {
          if (snippetObj.snippet) {
            // Parse the snippet and get all script elements
            const wrapper = document.createElement("div");
            wrapper.innerHTML = snippetObj.snippet.trim();
            const scripts = wrapper.querySelectorAll("script"); // Get all script tags

            scripts.forEach((script) => {
              const existingScript = Array.from(
                document.head.querySelectorAll("script")
              ).find(
                (s) =>
                  (script.src && s.src === script.src) || // Check if the same external script
                  (!script.src && s.textContent === script.textContent) // Check if inline script content is the same
              );

              // Only add the script if it's not already in the document
              if (!existingScript) {
                const newScript = document.createElement("script");
                if (script.src) {
                  newScript.src = script.src;
                  newScript.async = true;
                } else {
                  newScript.textContent = script.textContent;
                }

                document.head.appendChild(newScript);
              }
            });
          }
        });
      } else {
        console.error("Expected an array but received:", typeof activeSnippets);
      }
    } catch (error) {
      console.error("Error fetching analytics snippets:", error);
    }
  };

  useEffect(() => {
    if (!snippetsInjected.current) {
      injectSnippets();
      snippetsInjected.current = true; // Set the flag to true after injection
    }
  }, []);

  return (
    <div>
      <Header />
      <MobileMenu />
      <main>{children}</main>
      <Footer />
      <MobileFooter />
    </div>
  );
}

export default Layout;
